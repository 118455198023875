export const DEFAULT_COLOR = "#084298";
export const DEFAULT_POSITION = "right";
export const DEFAULT_STYLE = "bubble";
export const CW_IDENTIFIER = "bn_identifier";
export const CW_VISITOR = "bn_vistor";
export const CW_SESSION = "bn_session";
export const LOCATION_KEY = "bn_location";
export const SHOW = 0;
export const HIDE = 1;
export const SESSION_EXPIRE_TIME = 2 * (60 * 60); // hr(s)
export const COOKIE_EXPIRE_TIME = 7; //days(s)
export const VISITOR_PREFIX = "Visitor#";

export const MESSAGE_TYPES = {
  chat: 0,
  navigation: 1,
  activity: 2,
};

export const Visitor_status = {
  inactive: 0,
  active: 1,
  idle: 2,
};

export const chat_status = {
  no_action: 0,
  started: 1,
  ended: 2,
};

export const COOKIE_CONFIG = {
  expires: COOKIE_EXPIRE_TIME,
  sameSite: "None", // Allow cross-site cookies
  secure: true,
};

export const CHATBOT_CORE_API_URL = process.env.REACT_APP_CHATBOT_CORE_API_URL;
export const CHATBOT_MODELLING_API_URL =
  process.env.REACT_APP_CHATBOT_MODELLING_API_URL;

export const IFRAME_URL = process.env.REACT_APP_IFRAME_URL;

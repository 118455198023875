import React, { useEffect } from "react";
import "./App.css";
import { Route, Routes } from "react-router-dom";
import { rootRoute } from "./routes";
import { BN_IFrameHelper } from "../utils/Helper";
import useProjectParams from "../context/ProjectParamsContext";
import useVisitor from "../hooks/useVisitor";
import { useDispatch, useSelector } from "react-redux";
import {
  setVisitorFields,
  visitorSelector,
} from "../redux/reducers/visitor/visitorSlice";

const App = () => {
  const { setSpecificParam } = useProjectParams();
  const visitorState = useSelector(visitorSelector);
  const { bn_initChat } = useVisitor();
  const dispatch = useDispatch();

  useEffect(() => {
    const setUserParams = async () => {
      const urlParams = new URLSearchParams(window.location.search);
      const projectIdParam = urlParams.get("project_id");
      const botIdParam = urlParams.get("bot_id");
      const userIdParam = urlParams.get("user_id");
      const visitorId = urlParams.get("visitor_id");
      const visitorName = urlParams.get("visitor_name");
      const visitorEmail = urlParams.get("visitor_email");

      if (projectIdParam) {
        setSpecificParam("project_id", projectIdParam);
      }
      if (botIdParam) {
        setSpecificParam("bot_id", botIdParam);
      }
      if (userIdParam) {
        setSpecificParam("user_id", userIdParam);
      }

      if (visitorId) {
        setSpecificParam("identifier", visitorId);
      }

      if (projectIdParam && botIdParam && userIdParam) {
        setSpecificParam("from", "iframe");

        await dispatch(
          setVisitorFields({
            params: {
              project_id: projectIdParam,
              bot_id: botIdParam,
              user_id: userIdParam,
              visitor_id: visitorId ?? "",
              visitor_name: visitorName ?? "",
              visitor_email: visitorEmail ?? "",
            },
          })
        );
        await bn_initChat(projectIdParam, botIdParam);

        let obj = {
          identifier: visitorId || visitorState?.state?.bn_identifier,
        };
        recordVisitor(obj);
      }
    };

    setUserParams();
  }, []);

  const registerListeners = () => {
    window.addEventListener("message", (e) => {
      if (typeof e.data === "string" && !e.data.includes("webpackHot")) {
        let message = JSON.parse(e.data);
        if (message.event === "set-visitor") {
          recordVisitor(message);
        }
      }
    });
  };

  const recordVisitor = async (message) => {
    // setCookie("visitor_id", message?.identifier);
    localStorage.setItem("visitor_id", message?.identifier);
    setSpecificParam("visitor_id", message?.identifier);
  };

  useEffect(() => {
    setTimeout(() => {
      sendChatLoaded();
      registerListeners();
    }, 500);
  }, []);

  const sendChatLoaded = () => {
    BN_IFrameHelper.bn_sendMessage({
      event: "chatLoaded",
    });
  };

  return (
    <>
      <Routes>
        {rootRoute.map((route) => (
          <Route key={route.key} path={route.path} element={route.element} />
        ))}
      </Routes>
    </>
  );
};

export default App;

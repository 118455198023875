import Cookies from "js-cookie";
import {
  COOKIE_CONFIG,
  CW_IDENTIFIER,
  CW_SESSION,
  CW_VISITOR,
  SESSION_EXPIRE_TIME,
  VISITOR_PREFIX,
  Visitor_status,
} from "./constants";
import platform from "platform";
import axios from "axios";
import { bn_generateRandom } from "./Helper";

export const bn_events = {
  getIdentifier: () => Cookies.get(CW_IDENTIFIER),
  getVisitor: () => Cookies.get(CW_VISITOR),
  getSession: () => Cookies.get(CW_SESSION),
  getIpAddress: () => Cookies.get("bn_ipAddress"),
  getName: () => Cookies.get("bn_name"),
  getCountryName: () => Cookies.get("bn_countryName"),
  getCountryCode: () => Cookies.get("bn_countryCode"),
  getCity: () => Cookies.get("bn_city"),
  getStateProv: () => Cookies.get("bn_stateProv"),
  getUtmMedium: () => Cookies.get("bn_utm_medium") || Cookies.get("bn_medium"),
  getUtmSource: () =>
    Cookies.get("bn_utm_src") ||
    Cookies.get("bn_src") ||
    Cookies.get("bn_source") ||
    Cookies.get("bn_utm_source"),
  getGlickId: () => Cookies.get("bn_gclid"),
  getMsclikId: () => Cookies.get("bn_msclkid") || Cookies.get("bn_MSCLKID"),
  getUtmCampaign: () =>
    Cookies.get("bn_utm_campaign") || Cookies.get("bn_campaign"),
  getUtmTerm: () =>
    Cookies.get("bn_utm_term") ||
    Cookies.get("bn_kw") ||
    Cookies.get("bn_keyword"),
  getUtmContent: () =>
    Cookies.get("bn_utm_content") || Cookies.get("bn_content"),
  getPlatform: () => Cookies.get("bn_platform"),
  getDevice: () => Cookies.get("bn_device"),
  getChatStatus: () => Cookies.get("bn_chatStatus"),
  getOS: () => Cookies.get("bn_os"),
  getReferrer: () => Cookies.get("bn_referrer"),
  getProjectId: () => Cookies.get("project_id"),
  getBotId: () => Cookies.get("bot_id"),
  getUserId: () => Cookies.get("user_id"),
  getSessionStopped: () => Cookies.get("bn_sessionStopped"),

  reloadChat: async () => {
    await bn_initChat();
  },

  chatLoaded: async () => {
    const identifier = bn_events.getIdentifier();
    if (identifier) {
      const identifier = bn_events.getIdentifier();
      const ipAddress = bn_events.getIpAddress();
      const visitor = bn_events.getVisitor();
      const countryName = bn_events.getCountryName();
      const countryCode = bn_events.getCountryCode();
      const city = bn_events.getCity();
      const stateProv = bn_events.getStateProv();
      const project = bn_events.getProjectId();
      const user_id = bn_events.getUserId();
      const bot = bn_events.getBotId();

      const visitorObj = {
        name: bn_events.getName() || "",
        visitor_default: `${VISITOR_PREFIX}${visitor}`,
        identifier,
        visitor,
        ipAddress,
        countryName,
        countryCode,
        city,
        stateProv,
        status: Visitor_status.active,
        totalVisits: 0,
        totalChats: 0,
        landingPage: window.location.href,
        platform: bn_events.getPlatform(),
        os: bn_events.getOS(),
        device: bn_events.getDevice(),
        currentPage: window.location.href,
        lastActivity: new Date(),
        referrer: bn_events.getReferrer() || null,
        isBanned: false,
        project,
        user_id,
        bot,
        created_at: new Date(),
      };
    } else {
      await bn_loadVisitor();
    }
  },
};

export const bn_initChat = async () => {
  bn_platformSetup();
  bn_campaignTracking();
  await bn_locationSetup();
  await bn_events.chatLoaded();
};

export const bn_initPostMessageCommunication = () => {
  // window.onmessage = (e) => {
  //   try {
  //     if (typeof e.data === "string") {
  //       let data = JSON.parse(e.data);
  //       console.log("data", data);

  //       if (typeof bn_events[data.event] === "function") {
  //         bn_events[data.event](data);
  //       }
  //     }
  //   } catch (error) {
  //     console.log("Error: ", error.message);
  //   }
  // };

  window.addEventListener("message", (event) => {
    try {
      const message = JSON.parse(event.data);
      if (message.frameId === "chatbot_widget") {
        if (typeof bn_events[message.event] === "function") {
          bn_events[message.event](message);
        }
      }

      // Add logic to handle the message in the parent window as needed
    } catch (error) {
      console.error("Error parsing message in parent:", error);
    }
  });
};

const bn_updateSessionTime = () => {
  const expire_time = new Date();
  expire_time.setTime(expire_time.getTime() + SESSION_EXPIRE_TIME * 1000);
  const cookie_config = {
    expires: expire_time,
    sameSite: "None", // Allow cross-site cookies
    secure: true,
  };
  const session = bn_events.getSession();
  Cookies.set(CW_SESSION, session, cookie_config);
};

const bn_updateCookieTime = () => {
  const visitor = bn_events.getVisitor();
  const identifier = bn_events.getIdentifier();
  Cookies.set(CW_VISITOR, visitor, COOKIE_CONFIG);
  Cookies.set(CW_IDENTIFIER, identifier, COOKIE_CONFIG);
};

const bn_generateSession = async () => {
  const expire_time = new Date();
  expire_time.setTime(expire_time.getTime() + SESSION_EXPIRE_TIME * 1000);
  const cookie_config = {
    expires: expire_time,
    sameSite: "None", // Allow cross-site cookies
    secure: true,
  };
  const session_Id = await bn_generateRandom(20);
  Cookies.set(CW_SESSION, session_Id, cookie_config);
};

const bn_setActivity = async () => {
  bn_updateSessionTime();
  bn_updateCookieTime();
};

const bn_changeVisitorStatus = async (status) => {
  const identifier = bn_events.getIdentifier();
  const getSessionStopped = bn_events.getSessionStopped();
  if (getSessionStopped === "no") {
    if (!Cookies.get(CW_SESSION) && status === "visible") {
      await bn_generateSession();
      await bn_setActivity();
    }
    // let visitorObj = {
    //   identifier,
    //   isAway: status === "hidden",
    //   status: Visitor_status.active,
    //   lastActivity: serverTimestamp(),
    // };
    // await updateVisitor(identifier, visitorObj);
    bn_updateSessionTime();
    bn_updateCookieTime();
  }
};

export const bn_onVisibilityChangeEvent = () => {
  const bn_handleVisibilityChange = async () => {
    await bn_changeVisitorStatus(document.visibilityState);
  };
  document.addEventListener(
    "visibilitychange",
    bn_handleVisibilityChange,
    false
  );

  window.addEventListener(
    "focus",
    async function () {
      await bn_changeVisitorStatus("visible");
    },
    false
  );
  window.addEventListener(
    "blur",
    async function () {
      await bn_changeVisitorStatus("hidden");
    },
    false
  );
};

// In each tab
export function bn_trackTabUsage() {
  const tabKey = "bn_tabs"; // Unique key for your app
  const tabId = Date.now(); // Use a unique identifier for this tab

  // Check if this tab is already in sessionStorage
  if (!sessionStorage.getItem(tabKey)) {
    // Add this tab to sessionStorage
    sessionStorage.setItem(tabKey, "active");
    let tabsFromCookies = Cookies.get(tabKey);
    // Add this tab to the list of active tabs in localStorage
    const activeTabs = (tabsFromCookies && JSON.parse(tabsFromCookies)) || [];
    activeTabs.push(tabId);
    Cookies.set(tabKey, JSON.stringify(activeTabs));
  }

  // Listen for the window/tab close event
  window.addEventListener("beforeunload", (event) => {
    // event.preventDefault();
    // Remove this tab from sessionStorage
    sessionStorage.removeItem(tabKey);

    // Notify other tabs to close this tab
    let tabsFromCookies = Cookies.get(tabKey);
    // Add this tab to the list of active tabs in localStorage
    const activeTabs = (tabsFromCookies && JSON.parse(tabsFromCookies)) || [];
    const updatedTabs = activeTabs.filter((id) => id !== tabId);
    Cookies.set(tabKey, JSON.stringify(updatedTabs));

    if (updatedTabs?.length === 0) {
      // All tabs are closed, execute your code
    }
    return false;
  });
}

export const storageListener = () => {};

const bn_platformSetup = () => {
  let expire_time = new Date();
  expire_time.setTime(expire_time.getTime() + SESSION_EXPIRE_TIME * 1000);
  const cookie_config = {
    expires: expire_time,
    sameSite: "None", // Allow cross-site cookies
    secure: true,
  };
  const device =
    navigator.userAgent.match(/iPad|iPhone|iPod|Android|BlackBerry/i) != null
      ? "mobile"
      : "desktop";
  Cookies.set("bn_platform", platform.name.toLowerCase(), cookie_config);
  Cookies.set("bn_os", platform.os.family.toLowerCase(), cookie_config);
  Cookies.set("bn_device", device);
};

const bn_campaignTracking = () => {
  const currURL = new URL(window.location.href);
  const urlSearchParams = new URLSearchParams(currURL.search);

  const params = Object.fromEntries(urlSearchParams.entries());
  let expire_time = new Date();
  expire_time.setTime(expire_time.getTime() + SESSION_EXPIRE_TIME * 1000);
  let cookie_config = {
    expires: expire_time,
    sameSite: "None", // Allow cross-site cookies
    secure: true,
  };
  for (const row in params) {
    Cookies.set(row, params[row], cookie_config);
  }

  Cookies.set("bn_landingPage", window.location.href, cookie_config);
  if (document.referrer)
    Cookies.set("bn_referrer", document.referrer, cookie_config);
};

const bn_locationSetup = async () => {
  if (!bn_events.getIpAddress()) {
    const {
      data: { ip },
      status,
    } = await axios.get("https://api.ipify.org/?format=json").catch(() => {
      console.log("unable to get IP");
    });
    if (status === 200) {
      Cookies.set("bn_ipAddress", ip, COOKIE_CONFIG);

      const { data, status } = await axios
        .get(`https://pro.ip-api.com/json/${ip}`, {
          params: {
            key: "5XpThOAEkfgOvEJ",
          },
        })
        .catch(() => {
          console.log("unable to get location.");
        });

      if (status === 200) {
        const { country, regionName, countryCode, city } = data;
        Cookies.set("bn_countryName", country, COOKIE_CONFIG);
        Cookies.set("bn_countryCode", countryCode, COOKIE_CONFIG);
        Cookies.set("bn_stateProv", regionName, COOKIE_CONFIG);
        Cookies.set("bn_city", city, COOKIE_CONFIG);
      }
    }
  }
};

const bn_setVisitor = async () => {
  const identifier = bn_events.getIdentifier();
  const ipAddress = bn_events.getIpAddress();
  const visitor = bn_events.getVisitor();
  const countryName = bn_events.getCountryName();
  const countryCode = bn_events.getCountryCode();
  const city = bn_events.getCity();
  const stateProv = bn_events.getStateProv();
  const project = bn_events.getProjectId();
  const user_id = bn_events.getUserId();
  const bot = bn_events.getBotId();

  const visitorObj = {
    name: bn_events.getName() || "",
    visitor_default: `${VISITOR_PREFIX}${visitor}`,
    identifier,
    visitor,
    ipAddress,
    countryName,
    countryCode,
    city,
    stateProv,
    status: Visitor_status.active,
    totalVisits: 0,
    totalChats: 0,
    landingPage: window.location.href,
    platform: bn_events.getPlatform(),
    os: bn_events.getOS(),
    device: bn_events.getDevice(),
    currentPage: window.location.href,
    lastActivity: new Date(),
    referrer: bn_events.getReferrer() || null,
    isBanned: false,
    project,
    user_id,
    bot,
    created_at: new Date(),
  };
};

const bn_generateCookies = async () => {
  let projectId = await bn_events.getProjectId();
  let botId = await bn_events.getProjectId();
  let ipAddress = await bn_events.getIpAddress();
  let random = await bn_generateRandom(5);

  const identifier = await `${projectId}_${botId}_${ipAddress}_${random}`;
  const visitor = bn_generateRandom(8, "number");
  Cookies.set(
    CW_IDENTIFIER,
    identifier ?? bn_generateRandom(20),
    COOKIE_CONFIG
  );
  Cookies.set(CW_VISITOR, visitor, COOKIE_CONFIG);
  const name = `${VISITOR_PREFIX}${visitor}`;
  Cookies.set("bn_name", name, COOKIE_CONFIG);
  await bn_setVisitor();
};

export const bn_loadVisitor = async () => {
  let identifier = await Cookies.get(CW_IDENTIFIER);
  let session = await Cookies.get(CW_SESSION);

  let identifierCheck = identifier === "undefined" ? true : false;
  let sessionCheck = session === "undefined" ? true : false;

  if (!Cookies.get(CW_IDENTIFIER) || identifierCheck)
    await bn_generateCookies();
  if (!Cookies.get(CW_SESSION) || sessionCheck) await bn_generateSession();
  Cookies.set("bn_sessionStopped", "no");
  await bn_setActivity();
};

import React from "react";
import { BN_IFrameHelper, getAgentInitials } from "../../../../utils/Helper";
import { Popconfirm } from "antd";
import "../../../../assets/style/global.css";
import "./index.css";
import { useNavigate } from "react-router-dom";
import { Avatar, Tooltip } from "antd";
import { modelling_api } from "../../../../services/modelling_api";
import Cookies from "js-cookie";
import useCustomization from "../../../../context/CustomizationContext";
import useProjectParams from "../../../../context/ProjectParamsContext";
import { bn_initChat } from "../../../../utils/generateUserDetails";
import { visitorSelector } from "../../../../redux/reducers/visitor/visitorSlice";
import { useSelector } from "react-redux";

const Header = () => {
  const navigate = useNavigate();
  const { customization } = useCustomization();
  const { convParams, setSpecificParam } = useProjectParams();
  const visitorState = useSelector(visitorSelector);

  const handleMinimize = () => {
    BN_IFrameHelper.bn_sendMessage({
      event: "bn_minimizeChat",
    });
  };

  const endSession = async () => {
    let user_id = convParams?.user_id;
    let project_id = convParams?.project_id;
    let bot_id = convParams?.bot_id;
    let visitor_id = convParams?.visitor_id;
    let session_id =
      localStorage.getItem("session_id") || convParams?.session_id;
    try {
      let response = await modelling_api.post(
        `end_session?user_id=${user_id}&project_id=${project_id}&bot_id=${bot_id}&session_id=${session_id}&visitor_id=${visitor_id}`
      );
      Cookies.remove("session_id");
      localStorage.removeItem("session_id");
      setSpecificParam("session_id", undefined);
      BN_IFrameHelper.bn_sendMessage({
        event: "reloadChat",
      });

      if (convParams?.from === "iframe") {
        await bn_initChat(convParams?.project_id, convParams?.bot_id);
        let visitor_identifier =
          visitorState?.visitor?.params?.visitor_id ??
          visitorState?.visitor?.bn_identifier;
        localStorage.setItem("visitor_id", visitor_identifier);
        setSpecificParam("visitor_id", visitor_identifier);
      }
    } catch (error) {
      console.log("error", error);
    }
  };

  const handleClose = async (event) => {
    await endSession();
    navigate("/chat");
  };

  return (
    <header
      className="headerWidget"
      style={{
        background: `${
          customization?.widget_header_color
            ? customization?.widget_header_color
            : `var(--active-color`
        }`,
      }}
    >
      <div className="header_row">
        {/* <div className="msg-icon" onClick={navigateToConv}>
          <i className="fa-solid fa-chevron-left"></i>
        </div> */}

        <div className="initials">
          <Avatar.Group
            maxCount={2}
            maxStyle={
              {
                // color: "#f56a00",
                // backgroundColor: "#fde3cf",
              }
            }
          >
            <Tooltip
              zIndex="202020202023"
              title={customization?.bot_name}
              placement="top"
            >
              <Avatar
                size="large"
                style={{
                  border: "none",
                  backgroundColor: "rgb(16 38 113)",
                }}
              >
                {getAgentInitials(customization?.bot_name, true)}
              </Avatar>
            </Tooltip>
          </Avatar.Group>
        </div>

        <div className="header_btns">
          <div className="icons-area">
            {convParams?.from !== "iframe" && (
              <i
                className="fa-solid fa-minus"
                title="Minimize"
                onClick={handleMinimize}
              ></i>
            )}

            <Popconfirm
              okButtonProps={{
                style: {
                  background: `${
                    customization?.widget_header_color
                      ? customization?.widget_header_color
                      : `var(--active-color`
                  }`,
                },
              }}
              zIndex="20202020202"
              title={"Are you sure you want to close chat?"}
              okText="Yes"
              cancelText="No"
              onConfirm={handleClose}
            >
              <i className="fas fa-close " title="End chat"></i>
            </Popconfirm>
          </div>
        </div>
      </div>
    </header>
  );
};

export default Header;

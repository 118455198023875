import React from "react";
import { Route, Routes } from "react-router-dom";
import { routes } from "../app/routes";
import { CustomizationProvider } from "../context/CustomizationContext";

const Layout = () => {
  return (
    <div>
      <CustomizationProvider>
        <Routes>
          {routes.map((route) => (
            <Route key={route.key} path={route.path} element={route.element} />
          ))}
        </Routes>
      </CustomizationProvider>
    </div>
  );
};

export default Layout;

import Cookies from "js-cookie";

export const bn_events = {
  getIdentifier: () => Cookies.get("bn_identifier"),
  getVisitor: () => Cookies.get("bn_vistor"),
  getSession: () => Cookies.get("bn_session"),
  getIpAddress: () => Cookies.get("ipAddress"),
  getName: () => Cookies.get("bn_name"),
  getCountryName: () => Cookies.get("bn_countryName"),
  getCountryCode: () => Cookies.get("bn_countryCode"),
  getCity: () => Cookies.get("bn_city"),
  getStateProv: () => Cookies.get("bn_stateProv"),
  getUtmMedium: () => Cookies.get("bn_utm_medium") || Cookies.get("bn_medium"),
  getUtmSource: () =>
    Cookies.get("bn_utm_src") ||
    Cookies.get("bn_src") ||
    Cookies.get("bn_source") ||
    Cookies.get("bn_utm_source"),
  getGlickId: () => Cookies.get("bn_gclid"),
  getMsclikId: () => Cookies.get("bn_msclkid") || Cookies.get("bn_MSCLKID"),
  getUtmCampaign: () =>
    Cookies.get("bn_utm_campaign") || Cookies.get("bn_campaign"),
  getUtmTerm: () =>
    Cookies.get("utm_term") ||
    Cookies.get("bn_kw") ||
    Cookies.get("bn_keyword"),
  getUtmContent: () =>
    Cookies.get("bn_utm_content") || Cookies.get("bn_content"),
  getPlatform: () => Cookies.get("bn_platform"),
  getDevice: () => Cookies.get("bn_device"),
  getChatStatus: () => Cookies.get("bn_chatStatus"),
  getOS: () => Cookies.get("bn_os"),
  getReferrer: () => Cookies.get("bn_referrer"),
  getSessionStopped: () => Cookies.get("bn_sessionStopped"),
};

export const getVisitor = async () => {
  const identifier = bn_events.getIdentifier();
  const ipAddress = bn_events.getIpAddress();
  const visitor = bn_events.getVisitor();
  const countryName = bn_events.getCountryName();
  const countryCode = bn_events.getCountryCode();
  const city = bn_events.getCity();
  const stateProv = bn_events.getStateProv();

  const visitorObj = {
    name: bn_events.getName() || "",
    visitor_default: `Visitor#${visitor}`,
    identifier,
    visitor,
    ipAddress,
    countryName,
    countryCode,
    city,
    stateProv,
    totalVisits: 0,
    landingPage: window.location.href,
    platform: bn_events.getPlatform(),
    os: bn_events.getOS(),
    device: bn_events.getDevice(),
    currentPage: window.location.href,
    lastActivity: new Date(),
    referrer: bn_events.getReferrer() || null,
    isBanned: false,
    created_at: new Date(),
  };

  return visitorObj;
  // await updateVisitor(identifier, visitorObj);
};

import React, { useEffect, useState, useRef } from "react";
import { Input } from "antd";
import "../../../../assets/style/global.css";
import "./index.css";
import { AudioRecorder, useAudioRecorder } from "react-audio-voice-recorder";

const Send = (props) => {
  const textareaRef = useRef(null);
  const [text, setText] = useState("");
  const [showEmojiPanel, setShowEmojiPanel] = useState(false);
  const [uploading, setUploading] = useState(false);
  const [showTextBox, setShowTextBox] = useState(true);
  const {
    focus,
    setScrollHeight,
    setInputFocued,
    sendMessage,
    setMessages,
    messages,
    end,
  } = props;
  const recorderControls = useAudioRecorder(
    {
      noiseSuppression: true,
      echoCancellation: true,
    },
    (err) => console.table(err) // onNotAllowedOrFound
  );
  const toggleEmojiPanel = () => {
    setShowEmojiPanel(!showEmojiPanel);
    textareaRef.current.focus();
  };
  const handleTextareaFocus = () => {
    setInputFocued(true);
    textareaRef.current.focus();
    // markMessageRead();
  };
  const handleBlur = () => {
    setInputFocued(false);
    textareaRef.current.blur();
  };
  const onEmojiChange = (event, emojiObject) => {
    textareaRef.current.focus();
    setText((prevState) => {
      return prevState.concat(emojiObject.emoji);
    });
  };

  const handleFileInput = (file) => {
    setUploading(true);
  };

  const handleMessageSent = async () => {
    if (text?.trim()) {
      sendMessage({ msg: text }, "text");
      setText("");
    }
  };

  const handleOnFilePaste = (event) => {
    if (event.clipboardData.files.length > 0) {
      handleFileInput(event.clipboardData.files[0]);
    }
  };

  const myDivRef = useRef(null);
  useEffect(() => {
    // Create a new ResizeObserver instance
    const resizeObserver = new ResizeObserver((entries) => {
      for (let entry of entries) {
        const { height } = entry.contentRect;
        setScrollHeight(`calc(100vh - 68px - ${height}px + -20px) `);
      }
    });

    // Observe the target element (myDivRef.current)
    if (myDivRef.current) {
      resizeObserver.observe(myDivRef.current);
    }

    // Clean up the observer on component unmount
    return () => {
      if (myDivRef.current) {
        resizeObserver.unobserve(myDivRef.current);
      }
    };
  }, []);

  const handleKeyPress = (e) => {
    if (e.key === "Enter" && !e.shiftKey) {
      e.preventDefault();
      handleMessageSent();
    }
  };

  const addAudioElement = async (blob) => {
    const url = URL.createObjectURL(blob);

    const newObject = {
      id: messages.length + 1,
      voice_path: url,
      timestamp: new Date(),
      isVisitor: true,
    };
    setMessages([...messages, newObject]);
    await sendMessage(blob, "file");
  };

  useEffect(() => {
    setTimeout(() => {
      setShowTextBox(!recorderControls?.isRecording);
    }, 100);
  }, [recorderControls?.isRecording]);

  return (
    <div onPaste={handleOnFilePaste} className="container">
      <div className="send-box" ref={myDivRef}>
        <div className="flex_between send-icons">
          <div className="flex_items area-1">
            {showTextBox && (
              <div style={{ width: "100%", paddingTop: "5px" }}>
                <Input.TextArea
                  autoSize={{
                    minRows: 1,
                    maxRows: 5,
                  }}
                  disabled={end}
                  ref={textareaRef}
                  onFocus={handleTextareaFocus}
                  onBlur={handleBlur}
                  className={
                    focus ? "visitor-text-area active" : "visitor-text-area"
                  }
                  value={text}
                  onChange={(e) => {
                    setText(e.target.value);
                  }}
                  placeholder="Type Here..."
                  onKeyDown={handleKeyPress}
                  onClick={(e) => setShowEmojiPanel(false)}
                />
              </div>
            )}
          </div>
          <div className="flex_items ">
            <div className="flex_items area-2-btns">
              <AudioRecorder
                onRecordingComplete={(blob) => addAudioElement(blob)}
                recorderControls={recorderControls}
                showVisualizer={true}
              />
            </div>
            <div>
              <i
                onClick={handleMessageSent}
                className="fa-solid fa-paper-plane"
              ></i>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default React.memo(Send);

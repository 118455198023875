import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  visitor: {},
};

export const visitorSlice = createSlice({
  name: "visitor",
  initialState,
  reducers: {
    setVisitorFields: (state, { payload }) => {
      let updatedState = {
        ...state.visitor,
        ...payload,
      };

      state.visitor = updatedState;
    },
  },
});

// Action creators are generated for each case reducer function
export const { setVisitorFields } = visitorSlice.actions;
export const visitorSelector = (state) => state.visitor;

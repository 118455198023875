import React, { useState, createContext, useContext } from "react";
import { core_api } from "../../services/core_api";

let defaultCustomization = {};

const initialState = {
  customization: defaultCustomization,
  setCustomizaion: () => {},
};

const CustomizationContext = createContext(initialState);

export const CustomizationProvider = ({ children }) => {
  const [customization, setCustomization] = useState(
    initialState.customization
  );
  const [customizationLoading, setCustomizationLoading] = useState(false);
  const [bot, setBot] = useState({});

  const getCustomization = async (botId, projectId) => {
    try {
      setCustomizationLoading(true);
      if (Object.keys(customization)?.length === 0) {
        let response = await core_api.get(
          `customization/?bot_id=${botId}&project_id=${projectId}`
        );
        if (response?.data) setCustomization(response?.data);
      }
    } catch (error) {
      console.log("Err", error);
    } finally {
      setCustomizationLoading(false);
    }
  };

  const getBot = async (botId) => {
    try {
      // let response = await core_api.get(`bot/${botId}`);
      // if (response?.data) setBot(response?.data);
    } catch (error) {
      console.log("Err", error);
    }
  };
  return (
    <CustomizationContext.Provider
      value={{
        customization,
        setCustomization,
        getCustomization,
        bot,
        getBot,
        customizationLoading,
      }}
    >
      <>{children}</>
    </CustomizationContext.Provider>
  );
};

const useCustomization = () => {
  const context = useContext(CustomizationContext);

  if (context === undefined) {
    throw new Error("useAuth must be used within a CustomizationContext");
  }
  return context;
};

export default useCustomization;

import { useDispatch, useSelector } from "react-redux";
import {
  setVisitorFields,
  visitorSelector,
} from "../redux/reducers/visitor/visitorSlice";
import {
  CW_IDENTIFIER,
  CW_SESSION,
  CW_VISITOR,
  SESSION_EXPIRE_TIME,
  VISITOR_PREFIX,
  Visitor_status,
} from "../utils/constants";
import platform from "platform";
import axios from "axios";
import { bn_generateRandom } from "../utils/Helper";
import { useState } from "react";

export default function useVisitor() {
  const dispatch = useDispatch();
  const state = useSelector(visitorSelector);

  const bn_events = {
    getIdentifier: () => state?.visitor[CW_IDENTIFIER],
    getVisitor: () => state?.visitor[CW_VISITOR],
    getSession: () => state?.visitor[CW_SESSION],
    getIpAddress: () => state?.visitor["bn_ipAddress"],
    getName: () => state?.visitor["bn_name"],
    getCountryName: () => state?.visitor["bn_countryName"],
    getCountryCode: () => state?.visitor["bn_countryCode"],
    getCity: () => state?.visitor["bn_city"],
    getStateProv: () => state?.visitor["bn_stateProv"],
    getUtmMedium: () =>
      state?.visitor["bn_utm_medium"] || state?.visitor["bn_medium"],
    getUtmSource: () =>
      state?.visitor["bn_utm_src"] ||
      state?.visitor["bn_src"] ||
      state?.visitor["bn_source"] ||
      state?.visitor["bn_utm_source"],
    getGlickId: () => state?.visitor["bn_gclid"],
    getMsclikId: () =>
      state?.visitor["bn_msclkid"] || state?.visitor["bn_MSCLKID"],
    getUtmCampaign: () =>
      state?.visitor["bn_utm_campaign"] || state?.visitor["bn_campaign"],
    getUtmTerm: () =>
      state?.visitor["bn_utm_term"] ||
      state?.visitor["bn_kw"] ||
      state?.visitor["bn_keyword"],
    getUtmContent: () =>
      state?.visitor["bn_utm_content"] || state?.visitor["bn_content"],
    getPlatform: () => state?.visitor["bn_platform"],
    getDevice: () => state?.visitor["bn_device"],
    getChatStatus: () => state?.visitor["bn_chatStatus"],
    getOS: () => state?.visitor["bn_os"],
    getReferrer: () => state?.visitor["bn_referrer"],
    getProjectId: () => state?.visitor?.params?.project_id,
    getBotId: () => state?.visitor?.params?.bot_id,
    getUserId: () => state?.visitor?.params?.user_id,
    getSessionStopped: () => state?.visitor["bn_sessionStopped"],

    reloadChat: async () => {
      await bn_initChat();
    },

    chatLoaded: async (projectId, botId) => {
      const identifier = bn_events.getIdentifier();

      if (identifier) {
        let ipAddress = bn_events.getIpAddress();
        let visitor = bn_events.getVisitor();
        let countryName = bn_events.getCountryName();
        let countryCode = bn_events.getCountryCode();
        let city = bn_events.getCity();
        let stateProv = bn_events.getStateProv();
        let project = bn_events.getProjectId();
        let user_id = bn_events.getUserId();
        let bot = bn_events.getBotId();

        const visitorObj = {
          name: bn_events.getName() || "",
          visitor_default: `${VISITOR_PREFIX}${visitor}`,
          identifier,
          visitor,
          ipAddress,
          countryName,
          countryCode,
          city,
          stateProv,
          status: Visitor_status.active,
          totalVisits: 0,
          totalChats: 0,
          landingPage: window.location.href,
          platform: bn_events.getPlatform(),
          os: bn_events.getOS(),
          device: bn_events.getDevice(),
          currentPage: window.location.href,
          lastActivity: new Date(),
          referrer: bn_events.getReferrer() || null,
          isBanned: false,
          project,
          user_id,
          bot,
          created_at: new Date(),
        };
      } else {
        await bn_loadVisitor(projectId, botId);
      }
    },
  };

  const bn_initChat = async (projectId = null, botId = null) => {
    bn_platformSetup();
    bn_campaignTracking();
    await bn_locationSetup();
    await bn_events.chatLoaded(projectId, botId);
  };

  const bn_updateSessionTime = async () => {
    const expire_time = new Date();
    expire_time.setTime(expire_time.getTime() + SESSION_EXPIRE_TIME * 1000);
    const cookie_config = {
      expires: expire_time,
      sameSite: "None", // Allow cross-site cookies
      secure: true,
    };
    const session = bn_events.getSession();
    await dispatch(setVisitorFields({ [CW_SESSION]: session }));
  };

  const bn_updateCookieTime = async () => {
    const visitor = bn_events.getVisitor();
    const identifier = bn_events.getIdentifier();
    await dispatch(
      setVisitorFields({ [CW_VISITOR]: visitor, [CW_IDENTIFIER]: identifier })
    );
  };

  const bn_generateSession = async () => {
    const expire_time = new Date();
    expire_time.setTime(expire_time.getTime() + SESSION_EXPIRE_TIME * 1000);
    const cookie_config = {
      expires: expire_time,
      sameSite: "None", // Allow cross-site cookies
      secure: true,
    };
    const session_Id = await bn_generateRandom(20);
    await dispatch(setVisitorFields({ [CW_SESSION]: session_Id }));
  };

  const bn_setActivity = async () => {
    bn_updateSessionTime();
    bn_updateCookieTime();
  };

  const bn_platformSetup = async () => {
    let expire_time = new Date();
    expire_time.setTime(expire_time.getTime() + SESSION_EXPIRE_TIME * 1000);
    const cookie_config = {
      expires: expire_time,
      sameSite: "None", // Allow cross-site cookies
      secure: true,
    };
    const device =
      navigator.userAgent.match(/iPad|iPhone|iPod|Android|BlackBerry/i) != null
        ? "mobile"
        : "desktop";
    await dispatch(
      setVisitorFields({
        bn_platform: platform.name.toLowerCase(),
        bn_os: platform.os.family.toLowerCase(),
        bn_device: device,
      })
    );
  };

  const bn_campaignTracking = async () => {
    const currURL = new URL(window.location.href);
    const urlSearchParams = new URLSearchParams(currURL.search);

    const params = Object.fromEntries(urlSearchParams.entries());
    let expire_time = new Date();
    expire_time.setTime(expire_time.getTime() + SESSION_EXPIRE_TIME * 1000);
    let cookie_config = {
      expires: expire_time,
      sameSite: "None", // Allow cross-site cookies
      secure: true,
    };
    for (const row in params) {
      await dispatch(setVisitorFields({ params }));
    }
    await dispatch(setVisitorFields({ bn_landingPage: window.location.href }));
    if (document.referrer)
      await dispatch(setVisitorFields({ bn_referrer: document.referrer }));
  };

  const bn_locationSetup = async () => {
    if (!bn_events.getIpAddress()) {
      const {
        data: { ip },
        status,
      } = await axios.get("https://api.ipify.org/?format=json").catch(() => {
        console.log("unable to get IP");
      });
      if (status === 200) {
        await dispatch(setVisitorFields({ bn_ipAddress: ip }));
        const { data, status } = await axios
          .get(`https://pro.ip-api.com/json/${ip}`, {
            params: {
              key: "5XpThOAEkfgOvEJ",
            },
          })
          .catch(() => {
            console.log("unable to get location.");
          });
        if (status === 200) {
          const { country, regionName, countryCode, city } = data;
          await dispatch(
            setVisitorFields({
              bn_countryName: country,
              bn_countryCode: countryCode,
              bn_stateProv: regionName,
              bn_city: city,
            })
          );
        }
      }
    }
  };

  const bn_setVisitor = async () => {
    const identifier = bn_events.getIdentifier();
    const ipAddress = bn_events.getIpAddress();
    const visitor = bn_events.getVisitor();
    const countryName = bn_events.getCountryName();
    const countryCode = bn_events.getCountryCode();
    const city = bn_events.getCity();
    const stateProv = bn_events.getStateProv();
    const project = bn_events.getProjectId();
    const user_id = bn_events.getUserId();
    const bot = bn_events.getBotId();

    const visitorObj = {
      name: bn_events.getName() || "",
      visitor_default: `${VISITOR_PREFIX}${visitor}`,
      identifier,
      visitor,
      ipAddress,
      countryName,
      countryCode,
      city,
      stateProv,
      status: Visitor_status.active,
      totalVisits: 0,
      totalChats: 0,
      landingPage: window.location.href,
      platform: bn_events.getPlatform(),
      os: bn_events.getOS(),
      device: bn_events.getDevice(),
      currentPage: window.location.href,
      lastActivity: new Date(),
      referrer: bn_events.getReferrer() || null,
      isBanned: false,
      project,
      user_id,
      bot,
      created_at: new Date(),
    };
  };

  const bn_generateCookies = async (projectId, botId) => {
    let random = await bn_generateRandom(5);
    let ipAddress = state?.visitor["bn_ipAddress"];

    const identifier = await `${projectId}_${botId}_${random}`;

    const visitor = bn_generateRandom(8, "number");
    await dispatch(
      setVisitorFields({
        [CW_IDENTIFIER]: identifier ?? bn_generateRandom(20),
        [CW_VISITOR]: visitor,
      })
    );

    const name = `${VISITOR_PREFIX}${visitor}`;
    await dispatch(
      setVisitorFields({
        bn_name: name,
      })
    );
    await bn_setVisitor();
  };

  const bn_loadVisitor = async (projectId, botId) => {
    let identifier = await state?.visitor[CW_IDENTIFIER];
    let session = await state?.visitor[CW_SESSION];

    let identifierCheck = identifier === "undefined" ? true : false;
    let sessionCheck = session === "undefined" ? true : false;
    if (!state?.visitor[CW_IDENTIFIER] || identifierCheck)
      await bn_generateCookies(projectId, botId);
    if (!state?.visitor[CW_SESSION] || sessionCheck) await bn_generateSession();
    await dispatch(setVisitorFields({ bn_sessionStopped: "no" }));
    // await bn_setActivity();
  };

  return { bn_events, bn_initChat };
}
